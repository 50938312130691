import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function Footer() {
  const { ref, inView } = useInView({
    triggerOnce: false,  // Enable repeated animation on scrolling up and down
    threshold: 0.6       // Adjust this threshold as per your requirement
  });

  return (
    <motion.footer
      ref={ref}
      initial={{ opacity: 0, y: 100 }}  // Initial state before the animation starts
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}  // Animate in or out based on inView
      transition={{ duration: 0.8 }}  // Duration of the animation
      style={{ minHeight: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-3 mb-4">
            <div className="footer-sections">
              <img src="staticfiles/logos/1nobg.png" alt="logo" width="250px" />
              <br /><br />
              <h6 className="mb-2">Where Education Meets Innovation</h6>
              <p style={{ fontSize: '14px' }}>
                We are dedicated to developing robust educational portals and LMS systems that enhance learning and simplify management for educators.
              </p>
            </div>
          </div>

          <hr className="mb-4 responsive-hr" />

          <div className="col-6 col-md-6 col-lg-3 mb-4">
            <div className="footer-sections text-center whychooseus">
              <h2 className="mb-4">Why Choose Us</h2>
              <div className="mb-2">Comprehensive Content</div>
              <div className="mb-2">Interactive Learning</div>
              <div className="mb-2">Real-Time Feedback</div>
              <div className="mb-2">Personalized Learning</div>
            </div>
          </div>

          <div className="col-6 col-md-6 col-lg-3 mb-4">
            <div className="footer-sections text-center quicklinks">
              <h2 className="mb-4">Quick Links</h2>
              <div className="mb-2">Home</div>
              <div className="mb-2">Features</div>
              <div className="mb-2">About Us</div>
              <div className="mb-2">Contact Us</div>
            </div>
          </div>

          <hr className="mb-4 responsive-hr" />

          <div className="col-12 col-md-12 col-lg-3">
            <div className="footer-sections text-center">
              <h2 className="mb-4">Contact Details</h2>
              <div className="mb-2">Call : +918511962611</div>
              <div className="mb-2">Email : mail@ministudy.in</div>
              <div className="mb-2 mt-4 d-flex justify-content-around px-4">
                <span className="social-media-footer">
                  <img src="staticfiles/images/social_media/facebook.png" alt="facebook" />
                </span>
                <span className="social-media-footer">
                  <img src="staticfiles/images/social_media/twitter.png" alt="twitter" />
                </span>
                <span className="social-media-footer">
                  <img src="staticfiles/images/social_media/linkedin.png" alt="linkedin" />
                </span>
                <span className="social-media-footer">
                  <img src="staticfiles/images/social_media/instagram.png" alt="instagram" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-4 mb-4 responsive-hr" />
        <p className="text-center">&copy; 2024 miniStudy. All rights reserved.</p>
      </div>
    </motion.footer>
  );
}

export default Footer;
