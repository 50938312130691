import React from 'react'

function contact() {
  return (
    <div>
      <div className="aboutsection headersecabout">
    <div className="bgimg">
      <img src="staticfiles/aboutus_bg_ministudy.png" />
    </div>
    <div className="container text-center">
      <h1 className='section_title'>Contact Us</h1>
    </div>
  </div>
      <section className="home_contact_us">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex flex-column justify-content-center">
              <h1 className='section_title'>
                Have questions or feedback?
                <br />
                <img src="staticfiles/images/underline.png" className='sectionTitleImgUnderline mx-auto mx-md-0' alt="underline" style={{ width: '100px' }} />
              </h1>
              <br />
              <p>Thank you for your interest in miniStudy! We're here to assist you with any questions, concerns, or feedback you may have. Please don't hesitate to reach out to us using the following contact information: For assistance with technical issues, account-related inquiries, or general questions about miniStudy, our dedicated customer support team is available to help you. You can reach us via email at mail@ministudy.in or by filling out the contact form.</p>
            </div>
            <div className="col-md-6">
              <div className="homecontact d-flex justify-content-center">
                <div className="form-container">
                  <h5 className="text-center form-title">Contact Form</h5>
                  <form className="d-flex flex-column justify-content-center">
                    <div className="form-group">
                      <input type="text" className="form-input" id="name" placeholder=" " required />
                      <label htmlFor="name" className="form-label">Full Name</label>
                    </div>

                    <div className="form-group">
                      <input type="number" className="form-input" id="number" placeholder=" " required />
                      <label htmlFor="number" className="form-label">Contact</label>
                    </div>

                    <div className="form-group">
                      <input type="email" className="form-input" id="email" placeholder=" " required />
                      <label htmlFor="email" className="form-label">Email</label>
                    </div>
                    <div className="form-group">
                      <textarea className="form-input" placeholder=" " id="subject"></textarea>
                      <label htmlFor="subject" className="form-label">Subject</label>
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='contactus_page'>
      <div className="container">
        <div className='grid  md:grid-cols-2 md:gap-4'>
          <div className='col-span-1'>
          <iframe className='w-100 p-2 md:px-4 contactus_box rounded-3' height="500" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=550&amp;height=550&amp;hl=en&amp;q=B-001%20,%20veer%20savarkar%20Heights%201,%20vasantnagar%20Township,%20gota-aoganaj%20Road%20Ahamedabad+(miniStudy)&amp;t=p&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> <script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=dbc4ebb59105e85cb2f8a4f7a7899cc3796446f2'></script>
          </div>
          <div className='col-span-1'>
          <div className="container mx-auto px-4 py-8">
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
    {/* Box 1 */}
    <div className="shadow-md rounded-lg p-6 flex flex-col items-center contactus_box">
      <div className="text-blue-500 mb-4">
        {/* Your Icon Here */}
        <i class="fa-solid fa-phone"></i>
      </div>
      <p className="text-gray-400 text-sm mb-2">Phone</p>
      <p className="text-gray-300 text-sm text-center">
        <a href='tel: 8511962611'>+91 8511962611</a>
      </p>
    </div>
    {/* Box 2 */}
    <div className="shadow-md rounded-lg p-6 flex flex-col items-center contactus_box">
      <div className="text-blue-500 mb-4">
        {/* Your Icon Here */}
        <i class="fa-regular fa-address-card"></i>
      </div>
      <p className="text-gray-400 text-sm mb-2">Address</p>
      <p className="text-gray-300 text-sm text-center">
      <a href='https://maps.app.goo.gl/mG6xBR3ptZzuFZiw7'>B-001, Veer savarkar heights1.</a>
      </p>
    </div>
    {/* Box 3 */}
    <div className="shadow-md rounded-lg p-6 flex flex-col items-center contactus_box">
      <div className="text-blue-500 mb-4">
        {/* Your Icon Here */}
        <i class="fa-brands fa-instagram"></i>
      </div>
      <p className="text-gray-400 text-sm mb-2">Instagram</p>
      <p className="text-gray-300 text-sm text-center">
        <a className='word-wrap' href='https://www.instagram.com/ministudy.__/'>ministudy.__</a>
      </p>
    </div>
    {/* Box 4 */}
    <div className="shadow-md rounded-lg p-6 flex flex-col items-center contactus_box">
      <div className="text-blue-500 mb-4">
        {/* Your Icon Here */}
        <i class="fa-regular fa-envelope"></i>
      </div>
      <p className="text-gray-400 text-sm mb-2">Email</p>
      <p className="text-gray-300 text-sm text-center">
        <a href='mailto: mail.ministudy@gmail.com'>mail.ministudy@gmail.com</a>
      </p>
    </div>
    {/* Box 5 */}
    <div className="shadow-md rounded-lg p-6 flex flex-col items-center contactus_box">
      <div className="text-blue-500 mb-4">
        {/* Your Icon Here */}
        <i class="fa-brands fa-facebook"></i>
      </div>
      <p className="text-gray-400 text-sm mb-2">facebook</p>
      <p className="text-gray-300 text-sm text-center">
      <a href='https://www.facebook.com/profile.php?id=61562889696805'>MiniStudy Educationpoint</a>
      </p>
    </div>
    {/* Box 6 */}
    <div className="shadow-md rounded-lg p-6 flex flex-col items-center contactus_box">
      <div className="text-blue-500 mb-4">
        {/* Your Icon Here */}
        <i class="fa-brands fa-youtube"></i>
      </div>
      <p className="text-gray-400 text-sm mb-2">Youtube</p>
      <p className="text-gray-300 text-sm text-center">
        <a href='https://www.youtube.com/@miniStudy_t1'>@miniStudy_t1</a>
      </p>
    </div>
  </div>
</div>
          </div>
        </div>
      </div>
      </section>
    </div>
  )
}

export default contact