import React from 'react'
import Features from './features'

function services() {
  return (
    <>
    <div className="aboutsection headersecabout">
    <div className="bgimg">
      <img src="staticfiles/aboutus_bg_ministudy.png" />
    </div>
    <div className="container text-center">
      <h1 className='section_title'>Features</h1>
    </div>
  </div>

  <Features/>

  {/* We Provide Section */}
  <section>
        <div className="container text-center weprovide_section">
          <br />
          <br />
          <br />
          <h1 className="section_title">
            We Provide
            <br />
            <img src="staticfiles/images/underline.png" className='sectionTitleImgUnderline mx-auto' alt="underline" style={{ width: '100px' }} />
          </h1>
          <p>miniStudy is a cutting-edge educational portal crafted to cater to the diverse needs of the educational community. Whether you are a school administrator, student, faculty member, or parent, miniStudy provides a seamless and integrated experience to manage and participate in the educational process.</p>
          <br />
          <br />
          <div className="row d-flex justify-content-between">
            {[
              { title: 'Admin Panel', details: ['Add and manage standards, boards, subjects, chapters, and materials.', 'Maintain student and faculty records.','Manage Payments or Fees','View and respond to inquiries.','Create and manage timetables.','Monitor student attendance.','Send announcements to the school community.','View and address student doubts.','Create and manage tests.','Provide detailed student analysis and reports.'] },
              { title: 'Student Panel', details: ['Access and download study materials.', 'Upload doubts and questions.','View personal attendance records.','Stay updated with school events.','Participate in tests','Get Analysis and reports of tests and attendance',"View Syllabus & Know Today's Learning",'Students can get latest Announcements','Updated with Payments'] },
              { title: 'Teacher Panel', details: ['Address and solve student doubts.', 'Upload and update teaching materials.','Add & Conduct Tests','Take and view attendance records.','Access the school timetable.','View Syllabus and Mark it as Completed','Can get Latest Announcements','View Complete Report Card of his Students','View Events'] },
              { title: "Parent's Panel", details: ["Track child's attendance.", 'View analysis reports and test marks.','Stay informed with school announcements.'] }
            ].map((section, index) => (
              <div key={index} className="foursections homesec4">
                <div className="title">{section.title}</div>
                <hr />
                <div className="text-start listsection">
                  {section.details.map((detail, idx) => (
                    <div key={idx}>{detail}</div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <br />
          <br />
          <br />
        </div>
      </section>
    </>
  )
}

export default services