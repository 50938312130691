import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom'; 

function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const [visible, setVisible] = useState(true);
  const location = useLocation();
  let lastScrollTop = 0;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      // Add fixed class if scrolled more than 30px
      if (scrollTop > 30) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }

      // Show or hide navbar based on scroll direction
      if (scrollTop > 50) {
        setVisible(scrollTop < lastScrollTop || scrollTop < 50);
      } else {
        setVisible(true);
      }
      
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleMenuToggle = () => {
    const sideMenu = document.querySelector('.side-menu');
    const overlay = document.querySelector('.overlay');
    sideMenu.classList.toggle('open');
    overlay.classList.toggle('active');
  };

  const closeMenu = () => {
    const sideMenu = document.querySelector('.side-menu');
    const overlay = document.querySelector('.overlay');
    sideMenu.classList.remove('open');
    overlay.classList.remove('active');
  };

  const isActive = (path) => location.pathname === path ? 'activenav' : '';

  return (
    <>
      {/* Navbar */}
      <nav className={`navbar-ministudy ${scrolled ? 'fixed' : ''} ${visible ? 'visible' : 'hidden'}`}>
        <div className="container">
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col-6 col-md-4">
              <img src="/staticfiles/logos/1nobg.png" className="navlogo" alt="logo" />
            </div>
            <div className="col-6 d-flex align-items-center justify-content-end d-md-none">
              <div className="menu-toggle" onClick={handleMenuToggle}>
                &#9776; {/* Hamburger menu icon */}
              </div>
            </div>
            <div className="col-md-8 d-none d-md-flex align-items-center justify-content-end">
              <ul className="nav-links">
                <Link to="/" onClick={closeMenu}>
                  <li className={isActive('/')}>Home</li>
                </Link>
                <Link to="/about" onClick={closeMenu}>
                  <li className={isActive('/about')}>About Us</li>
                </Link>
                <Link to="/feature" onClick={closeMenu}>
                  <li className={isActive('/feature')}>Features</li>
                </Link>
                <Link to="/contact" onClick={closeMenu}>
                  <li className={isActive('/contact')}>Contact Us</li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      {/* Side Menu */}
      <div className="side-menu">
        <ul className="side-nav-links">
        <Link to="/" onClick={closeMenu}>
                  <li className={isActive('/')}>Home</li>
                </Link> 
                <Link to="/about" onClick={closeMenu}>
                  <li className={isActive('/about')}>About Us</li>
                </Link>
                <Link to="/feature" onClick={closeMenu}>
                  <li className={isActive('/feature')}>Features</li>
                </Link>
                <Link to="/contact" onClick={closeMenu}>
                  <li className={isActive('/contact')}>Contact Us</li>
                </Link>
        </ul>
      </div>

      {/* Overlay */}
      <div className="overlay" onClick={closeMenu}></div>
    </>
  );
}

export default Navbar;
