document.addEventListener('DOMContentLoaded', () => {
    const cursor = document.querySelector('.custom-cursor');

    if (cursor) {
        function moveCursor(e) {
            const cursorX = e.clientX - cursor.clientWidth / 2;
            const cursorY = e.clientY - cursor.clientHeight / 2;
            cursor.style.transform = `translate3d(${cursorX}px, ${cursorY}px, 0)`;
        }

        function changeCursorColor() {
            const randomColor = `hsl(${Math.random() * 360}, 100%, 50%)`;
            cursor.style.backgroundColor = randomColor;
            cursor.style.boxShadow = `0 0 10000px 40px ${randomColor}`;
        }

        function createBubble(e) {
            const bubble = document.createElement('div');
            bubble.className = 'bubble';
            document.body.appendChild(bubble);
            bubble.style.left = `${e.clientX}px`;
            bubble.style.top = `${e.clientY}px`;

            bubble.addEventListener('animationend', () => {
                bubble.remove();
            });
        }

        document.addEventListener('mousemove', moveCursor);
        document.addEventListener('click', createBubble);

        setInterval(changeCursorColor, 2000);
    }
});
