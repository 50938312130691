import logo from './logo.svg';
import './App.css';
import './styles.css'; 
import './scriptss.js';
import Navbar from './components/Navbar';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Features from './components/services.js';
import ContactUs from './components/contact.js';
let abc = "hello wolrd"

function App() {
  return (
    <>
      <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/feature" element={<Features />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
    </Router>
    <Footer />
    
    </>
  );
}

export default App;
