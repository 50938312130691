import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Features = () => {

  const { ref, inView } = useInView({
    triggerOnce: false,   // Set to false if you want to animate every time the user scrolls into view
    threshold: 0.2        // Adjust the threshold as per your needs
  });

  return (
    <>
     
      {/* Features Section */}
      <motion.section 
        className="Home_feature_sections"
        ref={ref}  // Ref for intersection observer
        initial={{ opacity: 0, y: 100 }}  // Animation starts when out of view
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}  // Trigger animation based on scroll
        transition={{ duration: 0.8 }}  // Duration of the animation
      >
        <div className="container text-center">
          <h1 className="section_title">
            Features
            <br />
            <img src="staticfiles/images/underline.png" className='sectionTitleImgUnderline mx-auto' alt="underline" style={{ width: '100px' }} />
          </h1>

          <br />
          <div className="row d-flex justify-content-between">
            {[
              { src: 'students.svg', title: 'Manage Students' },
              { src: 'timetable.svg', title: 'Sectionwise Timetable' },
              { src: 'Test.svg', title: 'Conduct Tests' },
              { src: 'materials.svg', title: 'Upload Materials' },
              { src: 'Analysis.svg', title: 'Student Analysis' },
              { src: 'events.svg', title: 'Events' },
              { src: 'announcements.svg', title: 'Announcements' },
              { src: 'doubts.svg', title: 'Address Doubts' },
              { src: 'inquiry.svg', title: 'Inquiries' },
              { src: 'attendance.svg', title: 'Take Attendance' }
            ].map((feature, index) => (
              <div key={index} className="featurebox">
                <img src={`staticfiles/feature_icons/${feature.src}`} alt={feature.title} />
                <div className="title">{feature.title}</div>
              </div>
            ))}
          </div>
        </div>
        </motion.section>
    </>
  );
};

export default Features;
