import React from 'react';
import Features from './features'

const AboutUs = () => {
    return (
      
        <>
  <div className="aboutsection headersecabout">
    <div className="bgimg">
      <img src="staticfiles/aboutus_bg_ministudy.png" />
    </div>
    <div className="container text-center">
      <h1 className='section_title'>About Us</h1>
    </div>
  </div>
  <div className="aboutsection">
    <div className="container text-center my-4">
      <h1 className="section_title">
        miniStudy
        <img
          src="staticfiles/images/underline.png"
          alt="underline" className='sectionTitleImgUnderline mx-auto'
          style={{ width: 100 }}
        />
      </h1>
      <h4 className='mb-3'>Where Education Meets Innovation</h4>
      <p>
        Welcome to miniStudy, your comprehensive educational portal designed to
        streamline and enhance the learning experience for schools, students,
        faculties, and parents. At miniStudy, we believe in empowering education
        through technology, offering a user-friendly platform that brings
        together all the essential tools and resources needed for modern
        education. Our mission is to foster a collaborative and efficient
        learning environment that supports academic excellence and personal
        growth.
      </p>
    </div>
  </div>

   {/* Features Section */}
   <Features/>

  <div className="aboutsection">
    <div className="container">
      <div className="row">
        <div className="col-md-6 d-flex flex-column justify-content-center">
          <h1 classname="section_title">
            FOR SCHOOLS
            <br />
            <img
              src="staticfiles/images/underline.png"
              alt="underline" className='sectionTitleImgUnderline'
              style={{ width: 100 }}
            />
          </h1>
          <p className='mt-4'>
            Schools can efficiently manage their administrative tasks through
            the comprehensive admin panel. Administrators can add and organize
            standards, boards, subjects, chapters, and materials. They can
            manage student and faculty records, view inquiries, create
            timetables, and monitor student attendance. The admin panel also
            allows sending announcements, viewing student doubts, creating
            tests, and providing detailed student analyses.
          </p>

          <br/>
          <hr/>
          <br/>
          <h1 classname="section_title">
            FOR STUDENTS
            <br />
            <img
              src="staticfiles/images/underline.png"
              alt="underline" className='sectionTitleImgUnderline'
              style={{ width: 100 }}
            />
          </h1>
          <p className='mt-4'>
          Students have access to a rich array of features designed to support their learning journey. Through the student panel, they can view and download study materials, upload their doubts, and track their attendance. Students can stay informed about school events, participate in tests, and take advantage of various other features aimed at enhancing their academic experience.
          </p>

        </div>
        <div className="col-md-6">
          <img src="staticfiles/about_compo.png" className='mx-auto'   style={{ width: "80%" }} />
        </div>
      </div>
    </div>
  </div>


  <div className="aboutsection">
    <div className="container">
      <div className="row">
      <div className="col-md-6 order-2 order-md-1">
          <img src="staticfiles/Component 3.png" className='mx-auto'   style={{ width: "80%" }} />
        </div>
        <div className="col-md-6 order-1 order-md-2 d-flex flex-column justify-content-center">
          
          <h1 classname="section_title">
            FOR FACULTIES
            <br />
            <img
              src="staticfiles/images/underline.png"
              alt="underline" className='sectionTitleImgUnderline'
              style={{ width: 100 }}
            />
          </h1>
          <p className='mt-4'>
          Faculty members are empowered with tools to manage their teaching responsibilities effectively. They can solve student doubts, upload and edit teaching materials, take and view attendance records, and check the timetable. The teacher panel ensures that faculty can focus on delivering quality education while efficiently handling administrative tasks.
          </p>

          <br/>
          <hr/>
          <br/>
          <h1 classname="section_title">
            FOR PARENTS
            <br />
            <img
              src="staticfiles/images/underline.png"
              alt="underline" className='sectionTitleImgUnderline'
              style={{ width: 100 }}
            />
          </h1>
          <p className='mt-4'>
          Parents play a crucial role in a child's education, and miniStudy ensures they stay informed and engaged. The parent panel allows parents to monitor their child's attendance, view analysis reports and test marks, and stay updated with school announcements. This transparency helps parents support their child's academic progress and well-being.
          </p>
        </div>
        
      </div>
    </div>
  </div>

       
</>
  

    );
  };
  
  export default AboutUs;