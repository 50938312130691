import React from 'react';
import Features from './features'

const Home = () => {  
  return (
    <>
      {/* Hero Section */}
      <header className="hero d-flex align-items-center">
        <div className="hero_bg">
          <video autoPlay muted loop id="background-video">
            <source src="staticfiles/1992-153555258_tiny.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="container text-white text-center d-flex flex-column justify-content-center align-items-center">
          <img src="staticfiles/logos/2nobg.png" alt="textlogo" className="textlogoimg" />
          <h1 className="display-5 text-white">Where Education Meets Innovation</h1>
          <p className="lead">At miniStudy, we believe in the power of technology to transform education and empower learners of all ages to achieve their academic goals.</p>
          <a href="#services" className="btn btn-light mt-3">
            <i className="fa-brands fa-think-peaks"></i> Inquiry Now
          </a>
        </div>
      </header>

      {/* Features Section */}
      <Features/>

      {/* What We Do Section */}
      <section className="home_whatwedo">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-sm-12 col-md-6">
              <h1 className="section_title">
                WHAT WE DO
                <br />
                <img src="staticfiles/images/underline.png" className='sectionTitleImgUnderline mx-auto mx-md-0' alt="underline" style={{ width: '100px' }} />
              </h1>
              <p>Welcome to miniStudy, your comprehensive educational portal designed to streamline and enhance the learning experience for schools, students, faculties, and parents. At miniStudy, we believe in empowering education through technology, offering a user-friendly platform that brings together all the essential tools and resources needed for modern education. Our mission is to foster a collaborative and efficient learning environment that supports academic excellence and personal growth.</p>
            </div>
            <div className="col-sm-12 col-md-6 d-flex justify-content-center">
              <div className="phn_laptop_section">
                <img src="staticfiles/images/Component 2.png" className="laptop_img" alt="educational_plateform" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* We Provide Section */}
      <section>
        <div className="container text-center weprovide_section">
          <br />
          <br />
          <br />
          <h1 className="section_title">
            We Provide
            <br />
            <img src="staticfiles/images/underline.png" className='sectionTitleImgUnderline mx-auto' alt="underline" style={{ width: '100px' }} />
          </h1>
          <p>miniStudy is a cutting-edge educational portal crafted to cater to the diverse needs of the educational community. Whether you are a school administrator, student, faculty member, or parent, miniStudy provides a seamless and integrated experience to manage and participate in the educational process.</p>
          <br />
          <br />
          <div className="row d-flex justify-content-between">
            {[
              { title: 'Admin Panel', details: ['Add and manage standards, boards, subjects, chapters, and materials.', 'Maintain student and faculty records.','Manage Payments or Fees','View and respond to inquiries.','Create and manage timetables.','Monitor student attendance.','Send announcements to the school community.','View and address student doubts.','Create and manage tests.','Provide detailed student analysis and reports.'] },
              { title: 'Student Panel', details: ['Access and download study materials.', 'Upload doubts and questions.','View personal attendance records.','Stay updated with school events.','Participate in tests','Get Analysis and reports of tests and attendance',"View Syllabus & Know Today's Learning",'Students can get latest Announcements','Updated with Payments'] },
              { title: 'Teacher Panel', details: ['Address and solve student doubts.', 'Upload and update teaching materials.','Add & Conduct Tests','Take and view attendance records.','Access the school timetable.','View Syllabus and Mark it as Completed','Can get Latest Announcements','View Complete Report Card of his Students','View Events'] },
              { title: "Parent's Panel", details: ["Track child's attendance.", 'View analysis reports and test marks.','Stay informed with school announcements.'] }
            ].map((section, index) => (
              <div key={index} className="foursections homesec4">
                <div className="title">{section.title}</div>
                <hr />
                <div className="text-start listsection">
                  {section.details.map((detail, idx) => (
                    <div key={idx}>{detail}</div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <br />
          <br />
          <br />
        </div>
      </section>

      {/* Contact Us Section */}
      <section className="home_contact_us">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex flex-column justify-content-center">
              <h1 className='section_title'>
                Have questions or feedback?
                <br />
                <img src="staticfiles/images/underline.png" className='sectionTitleImgUnderline mx-auto mx-md-0' alt="underline" style={{ width: '100px' }} />
              </h1>
              <br />
              <p>Thank you for your interest in miniStudy! We're here to assist you with any questions, concerns, or feedback you may have. Please don't hesitate to reach out to us using the following contact information: For assistance with technical issues, account-related inquiries, or general questions about miniStudy, our dedicated customer support team is available to help you. You can reach us via email at mail@ministudy.in or by filling out the contact form below.</p>
            </div>
            <div className="col-md-6">
              <div className="homecontact d-flex justify-content-center">
                <div className="form-container">
                  <h5 className="text-center form-title">Contact Form</h5>
                  <form className="d-flex flex-column justify-content-center">
                    <div className="form-group">
                      <input type="text" className="form-input" id="name" placeholder=" " required />
                      <label htmlFor="name" className="form-label">Full Name</label>
                    </div>

                    <div className="form-group">
                      <input type="number" className="form-input" id="number" placeholder=" " required />
                      <label htmlFor="number" className="form-label">Contact</label>
                    </div>

                    <div className="form-group">
                      <input type="email" className="form-input" id="email" placeholder=" " required />
                      <label htmlFor="email" className="form-label">Email</label>
                    </div>
                    <div className="form-group">
                      <textarea className="form-input" placeholder=" " id="subject"></textarea>
                      <label htmlFor="subject" className="form-label">Subject</label>
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
